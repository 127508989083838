<template>
  <v-row v-if="loading" align="center" justify="center" class="fill-height">
    <v-progress-circular
      :size="60"
      :width="5"
      color="primary"
      indeterminate
    ></v-progress-circular>
  </v-row>

  <v-container v-else>
    <v-card width="550" class="mx-auto pt-6 px-8 pb-8 mb-5">
      <div class="d-sm-flex justify-sm-space-between py-4">
        <div>
          <div class="font-regular-12 gray9--text">
            {{ $t("panel.businessesPage.posGatewaySettings.activationStatus") }}
          </div>

          <span
            v-if="gatewayModel.gatewayStatus === 'PENDING'"
            class="font-regular-10 info--text"
          >
            {{ $t("panel.businessesPage.posGatewaySettings.contactViaEmail") }}
          </span>

          <span
            v-else-if="gatewayModel.gatewayStatus === 'SUSPENDED'"
            class="font-regular-10 warning--text"
          >
            {{ $t("panel.businessesPage.posGatewaySettings.suspendByAdmin") }}
          </span>
        </div>

        <div>
          <v-chip
            small
            :color="statusColors.bg_color"
            :text-color="statusColors.text_color"
          >
            <span class="font-regular-10">{{
              gatewayModel.gatewayStatus
            }}</span>
          </v-chip>

          <v-btn
            v-if="
              gatewayModel.gatewayStatus === 'ACTIVE' ||
              gatewayModel.gatewayStatus === 'INACTIVE'
            "
            text
            color="transparent"
            height="21"
            class="ms-2"
            @click="openActivationModal"
          >
            <span
              class="font-semiBold-14 primary--text text-decoration-underline"
            >
              <template v-if="gatewayModel.gatewayStatus === 'ACTIVE'">
                {{
                  $t(
                    "panel.businessesPage.posGatewaySettings.inactivationRequest"
                  )
                }}
              </template>

              <template v-else-if="gatewayModel.gatewayStatus === 'INACTIVE'">
                {{
                  $t(
                    "panel.businessesPage.posGatewaySettings.activationRequest"
                  )
                }}
              </template>
            </span>
          </v-btn>
        </div>
      </div>
      <v-divider></v-divider>

      <div
        class="d-sm-flex align-sm-center justify-sm-space-between text-center py-4"
      >
        <div class="font-regular-12 gray9--text">
          {{ $t("panel.businessesPage.posGatewaySettings.currency") }}
        </div>

        <div class="d-sm-flex align-center align-sm-center">
          <div class="gray12--text d-inline-block truncate text-right">
            {{ selectedCurrenciesNames.toString() }}
          </div>

          <v-btn
            text
            color="transparent"
            height="21"
            @click="openCurrenciesModal"
            class="d-block mx-auto"
          >
            <span
              class="font-semiBold-14 primary--text text-decoration-underline"
            >
              {{ $t("panel.businessesPage.posGatewaySettings.edit") }}
            </span>
          </v-btn>
        </div>
      </div>
      <v-divider></v-divider>

      <div
        class="d-sm-flex align-sm-center justify-sm-space-between text-center py-4"
      >
        <div>
          <span class="font-regular-12 gray9--text me-1">
            {{ $t("panel.businessesPage.posGatewaySettings.commission") }}
          </span>

          <!--          <v-tooltip-->
          <!--            v-model="showCommissionTooltip"-->
          <!--            right-->
          <!--            :top="$vuetify.breakpoint.xs"-->
          <!--            color="white"-->
          <!--            content-class="gateway-settings-tooltip"-->
          <!--            class="gateway-settings-tooltip"-->
          <!--          >-->
          <!--            <template v-slot:activator="{ on, attrs }">-->
          <!--              <v-icon size="16" color="transparent" v-bind="attrs" v-on="on"-->
          <!--                >$info-->
          <!--              </v-icon>-->
          <!--            </template>-->
          <!--            <span class="font-regular-12 gray8&#45;&#45;text">-->
          <!--              {{-->
          <!--                $t(-->
          <!--                  "panel.businessesPage.posGatewaySettings.commissionTooltipDesc"-->
          <!--                )-->
          <!--              }}-->
          <!--            </span>-->
          <!--          </v-tooltip>-->
        </div>

        <div>
          <v-btn
            v-if="gatewaySettings.commissionType === 'DEFAULT'"
            :text="!commissionBtnLoading"
            color="transparent"
            height="21"
            @click="allCommissionsDisplay"
            :loading="commissionBtnLoading"
            class="d-block mx-auto px-0 default-btn"
            elevation="0"
          >
            <span
              class="font-semiBold-14 primary--text text-decoration-underline"
            >
              {{ $t("panel.businessesPage.posGatewaySettings.default") }}
            </span>
          </v-btn>
          <span v-else class="gray12--text">
            {{ gatewaySettings.commission }}%
          </span>
        </div>
      </div>
      <!--      <v-divider></v-divider>-->

      <!--      <div-->
      <!--        class="d-sm-flex align-sm-center justify-sm-space-between text-center py-4"-->
      <!--      >-->
      <!--        <div>-->
      <!--          <span class="font-regular-12 gray9&#45;&#45;text me-1">-->
      <!--            {{ $t("panel.businessesPage.posGatewaySettings.operationalFee") }}-->
      <!--          </span>-->

      <!--          <v-tooltip-->
      <!--            v-model="showOperationalFeeTooltip"-->
      <!--            right-->
      <!--            :top="$vuetify.breakpoint.xs"-->
      <!--            color="white"-->
      <!--            content-class="gateway-settings-tooltip"-->
      <!--            class="gateway-settings-tooltip"-->
      <!--          >-->
      <!--            <template v-slot:activator="{ on, attrs }">-->
      <!--              <v-icon size="16" color="transparent" v-bind="attrs" v-on="on"-->
      <!--                >$info-->
      <!--              </v-icon>-->
      <!--            </template>-->
      <!--            <span class="font-regular-12 gray8&#45;&#45;text">-->
      <!--              {{-->
      <!--                $t(-->
      <!--                  "panel.businessesPage.posGatewaySettings.operationalFeeTooltipDesc"-->
      <!--                )-->
      <!--              }}-->
      <!--            </span>-->
      <!--          </v-tooltip>-->
      <!--        </div>-->

      <!--        <div>-->
      <!--          <span-->
      <!--            v-if="gatewaySettings.feeSide === 'MERCHANT_SIDE'"-->
      <!--            class="gray12&#45;&#45;text"-->
      <!--          >-->
      <!--            {{ $t("panel.businessesPage.posGatewaySettings.operationalFee") }}-->
      <!--          </span>-->
      <!--        </div>-->
      <!--      </div>-->

      <v-dialog v-model="activationModal" width="468" persistent>
        <v-btn
          icon
          color="transparent"
          @click="closeActivationModal"
          class="ml-auto d-block"
        >
          <v-icon size="24">$close</v-icon>
        </v-btn>

        <gateway-activation-modal
          :id="gatewayModel.id"
          :status="gatewayModel.gatewayStatus"
          @update="getDetails"
          @close="closeActivationModal"
        />
      </v-dialog>

      <v-dialog v-model="currenciesModal" width="468" persistent>
        <currencies-modal
          :currencies="currencies"
          :gatewayCurrencies="gatewayModel.currenciesDto"
          :loading="submitLoading"
          @setCurrencies="updateCurrencies"
          @close="closeCurrenciesModal"
        />
      </v-dialog>
    </v-card>
    <v-dialog v-model="commissionModal" width="629">
      <v-row
        no-gutters
        justify="space-between"
        align="center"
        class="no-wrap px-4 pt-2 pb-2"
      >
        <span class="ps-9" />
        <span class="gray10--text font-medium-16">{{
          $t("panel.businessesPage.posGatewaySettings.modalCommissionTitle")
        }}</span>
        <v-btn
          icon
          color="transparent"
          @click="commissionModal = false"
          class="d-block"
        >
          <v-icon size="24">$close</v-icon>
        </v-btn>
      </v-row>

      <v-data-table
        fixed-header
        :headers="
          $t('panel.businessesPage.posGatewaySettings.modalCommissionTable')
        "
        :items="tableData"
        :loading="tableLoading"
        hide-default-footer
        :class="['px-4 pb-4', { 'no-data-wrapper-height': !tableData.length }]"
      >
        <template v-slot:item.currency="{ item }">
          <div class="d-flex align-center justify-start">
            <img height="20" :src="item.svglogo" :alt="item.iso" />
            <span class="ps-1">
              {{ item.iso }}
            </span>
          </div>
        </template>
        <template v-slot:item.commission="{ item }">
          {{ item.commission || setDefaultCommission }}%
        </template>
      </v-data-table>
    </v-dialog>
  </v-container>
</template>

<script>
import { MerchantService, CurrenciesService } from "@/services";
import CurrenciesModal from "../CreateGateway/CurrenciesModal";
import GatewayActivationModal from "./GatewayActivationModal";

export default {
  name: "pos-gateway-settings",
  props: ["id"],
  components: {
    GatewayActivationModal,
    CurrenciesModal,
  },
  data() {
    return {
      loading: false,
      submitLoading: false,
      currenciesModal: false,
      activationModal: false,
      showCommissionTooltip: false,
      showOperationalFeeTooltip: false,
      gatewaySettings: {},
      gatewayModel: {
        gatewayType: "POS",
        currenciesDto: [],
        gatewayStatus: "",
      },
      currencies: [],
      commissionModal: false,
      tableLoading: false,
      tableData: [],
      commissionBtnLoading: false,
      setDefaultCommission: "",
    };
  },
  computed: {
    selectedCurrenciesNames: {
      get() {
        if (
          JSON.stringify(this.gatewayModel.currenciesDto) ===
          JSON.stringify(this.currencies)
        ) {
          return this.$t(
            "panel.businessesPage.posGatewaySettings.allCurrencies"
          );
        } else {
          return this.gatewayModel.currenciesDto.map((elem) => {
            return " " + elem.iso + " (" + elem.network + ")";
          });
        }
      },
      set(val) {
        return val;
      },
    },
    statusColors() {
      const data = this.$t("panel.stateStatuses").find(
        (elem) => elem.status === this.gatewayModel.gatewayStatus
      );
      if (data) return data;
      else
        return this.$t("panel.stateStatuses").find(
          (elem) => elem.status === "OTHER"
        );
    },
  },
  mounted() {
    this.loading = true;
    Promise.all([
      this.getSettings(),
      this.getDetails(),
      this.getCurrencies(),
    ]).then(() => {
      this.loading = false;
    });
  },
  methods: {
    allCommissionsDisplay() {
      this.commissionBtnLoading = true;
      CurrenciesService.getSupportedCurrencies()
        .then((response) => {
          CurrenciesService.getDefaultCommission().then((res) => {
            this.setDefaultCommission = res.data.data;
            this.tableData = response.data.data.content;
            this.commissionModal = true;
          });
        })
        .finally(() => {
          this.commissionBtnLoading = false;
        });
    },
    getSettings() {
      return MerchantService.getGatewaySettingsById(this.id).then((res) => {
        this.gatewaySettings = res.data.data;
      });
    },
    getDetails() {
      return MerchantService.getGatewayById(this.id).then((res) => {
        this.gatewayModel = res.data.data;
      });
    },
    getCurrencies() {
      return CurrenciesService.getSupportedCurrencies().then((res) => {
        this.currencies = res.data.data.content;
      });
    },
    openCurrenciesModal() {
      this.currenciesModal = true;
    },
    openActivationModal() {
      this.activationModal = true;
    },
    closeActivationModal() {
      this.activationModal = false;
    },
    closeCurrenciesModal() {
      this.currenciesModal = false;
    },
    updateCurrencies(currencies) {
      this.submitLoading = true;
      const data = {
        id: this.id,
        currenciesDto: currencies,
      };
      MerchantService.updateGatewayCurrencies(data)
        .then((res) => {
          this.gatewayModel.currenciesDto = res.data.data.currenciesDto;
          this.$toast.success(res.data.message, {
            icon: this.$vuetify.icons.values.checkMarkDone,
          });
        })
        .finally(() => {
          this.submitLoading = false;
          this.closeCurrenciesModal();
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import "PosGatewaySettings";
</style>
