<template>
  <div>
    <v-btn icon color="transparent" @click="close" class="ml-auto d-block">
      <v-icon size="24">$close</v-icon>
    </v-btn>

    <div class="px-4 pb-6">
      <div class="font-medium-16 gray10--text mb-2 text-center">
        {{ $t("panel.businessesPage.createGateway.currency") }}
      </div>

      <div class="font-regular-12 gray7--text mb-6 text-center">
        {{ $t("panel.businessesPage.createGateway.chooseGatewayCurrencies") }}
      </div>

      <v-row class="mb-8 px-6 currencies">
        <v-col
          cols="6"
          md="4"
          v-for="(currency, index) in currencies"
          :key="index"
        >
          <div
            class="pt-1 px-1 pb-3 custom-border cursor-pointer text-center"
            :class="{
              'active-border': checkIsSelectedCurrency(currency),
            }"
            @click="addToSelectedCurrencies(currency)"
          >
            <v-row no-gutters>
              <v-col cols="1">
                <v-icon
                  v-if="checkIsSelectedCurrency(currency)"
                  size="20"
                  class="currencyCheckIcon"
                  >$filledCheckMark</v-icon
                >
              </v-col>

              <v-col cols="10" align-self="center">
                <v-img
                  :src="currency.svglogo"
                  width="24"
                  height="24"
                  class="mb-1 mx-auto"
                />
              </v-col>
            </v-row>

            <div class="font-regular-12 gray13--text text-truncate mb-1">
              {{ currency.name }}
            </div>

            <div class="font-regular-10 gray7--text">
              {{ currency.network }}
            </div>
          </div>
        </v-col>
      </v-row>

      <div
        class="d-md-flex align-center justify-space-between flex-md-nowrap flex-wrap"
      >
        <v-btn
          height="43"
          depressed
          color="primary"
          class="me-1 mb-3 mb-md-0 px-16 fill-width flex-shrink-1"
          :loading="loading"
          @click="setSelectedCurrencies"
        >
          <span class="font-semiBold-14 px-16 px-md-6">
            {{ $t("panel.businessesPage.createGateway.save") }}
          </span>
        </v-btn>

        <v-btn
          height="43"
          depressed
          color="gray2"
          class="px-16 fill-width flex-shrink-1"
          @click="close"
        >
          <span class="font-semiBold-14 px-14 px-md-4">
            {{ $t("panel.businessesPage.createGateway.cancel") }}
          </span>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "currencies-modal",
  props: {
    currencies: {
      type: Array,
      required: true,
    },
    gatewayCurrencies: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedCurrencies: [],
    };
  },
  mounted() {
    this.selectedCurrencies = [...this.gatewayCurrencies];
  },
  methods: {
    checkIsSelectedCurrency(item) {
      return this.selectedCurrencies.find(
        (elem) => elem.currencyId === item.currencyId
      );
    },
    addToSelectedCurrencies(item) {
      if (this.checkIsSelectedCurrency(item)) {
        this.selectedCurrencies = this.selectedCurrencies.filter(
          (elem) => elem.currencyId !== item.currencyId
        );
      } else {
        this.selectedCurrencies.push({
          currencyId: item.currencyId,
          iso: item.iso,
          network: item.network,
        });
      }
    },
    setSelectedCurrencies() {
      if (!this.selectedCurrencies.length) {
        this.$toast.error(
          this.$t(
            "panel.businessesPage.createGateway.atLeastOneSelectedCurrency"
          )
        );
        return;
      }
      this.$emit("setCurrencies", this.selectedCurrencies);
    },
    close() {
      this.selectedCurrencies = [...this.gatewayCurrencies];
      this.$emit("close");
    },
  },
};
</script>

<style scoped lang="scss">
@import "CurrenciesModal";
</style>
