<template>
  <div class="px-4 pb-6">
    <v-icon size="54" color="info" class="mx-auto d-block mb-3"
      >$warning</v-icon
    >

    <div class="font-medium-16 gray13--text text-center mb-2">
      <template v-if="status === 'ACTIVE'">
        {{ $t("panel.businessesPage.posGatewaySettings.inactivateGateway") }}
      </template>

      <template v-else-if="status === 'INACTIVE'">
        {{ $t("panel.businessesPage.posGatewaySettings.activateGateway") }}
      </template>
    </div>

    <div class="gray9--text text-center mb-8">
      <template v-if="status === 'ACTIVE'">
        {{ $t("panel.businessesPage.posGatewaySettings.sureToInactive") }}
      </template>

      <template v-else-if="status === 'INACTIVE'">
        {{ $t("panel.businessesPage.posGatewaySettings.sureToActive") }}
      </template>
    </div>

    <div class="d-flex align-sm-center justify-space-between">
      <v-btn
        width="210"
        height="43"
        depressed
        color="primary"
        :loading="loading"
        @click="changeStatus"
      >
        <span class="font-semiBold-14">
          {{ $t("panel.businessesPage.posGatewaySettings.save") }}
        </span>
      </v-btn>

      <v-btn width="210" height="43" depressed color="gray2" @click="close">
        <span class="font-semiBold-14">
          {{ $t("panel.businessesPage.posGatewaySettings.cancel") }}
        </span>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { MerchantService } from "@/services";

export default {
  name: "gateway-activation-modal",
  props: {
    id: {
      type: Number,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    changeStatus() {
      this.loading = true;
      const data = {
        gatewayId: this.id,
        gatewayStatus: this.status === "ACTIVE" ? "INACTIVE" : "ACTIVE",
      };
      MerchantService.changeGatewayStatus(data)
        .then((res) => {
          this.$toast.success(res.data.message, {
            icon: this.$vuetify.icons.values.checkMarkDone,
          });
          this.$emit("update");
        })
        .finally(() => {
          this.loading = false;
          this.close();
        });
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped></style>
